import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO(props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            url
            ogpImage
            author
          }
        }
      }
    `
  )

  // Page Data
  const lang = "en"
  const location = props.location
  const pageTitle = props.pageTitle
  const pageSlug = props.pageSlug
  const pageDescription = props.pageDescription
  const pageKeywords = props.pageKeywords
  const pageImage = props.pageImage

  // Site Data
  const siteTitle = site.siteMetadata.title
  const siteDescription = site.siteMetadata.description
  const commonKeywords = site.siteMetadata.keyword
  const siteUrl = site.siteMetadata.url
  const ogpImage = site.siteMetadata.ogpImage
  const siteAuthor = site.siteMetadata.author

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: pageSlug,
      }}
      title={pageTitle}
      defaultTitle={siteTitle}
    >
      {/* General tags */}
      <meta
        name="image"
        content={pageImage ? siteUrl + pageImage : siteUrl + ogpImage}
      />
      <meta
        name="description"
        content={pageDescription ? pageDescription : siteDescription}
      />
      <meta
        name="keyword"
        content={
          pageKeywords ? `${pageKeywords},${commonKeywords}` : commonKeywords
        }
      />

      {/* OpenGraph tags */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:url" content={location} />
      <meta
        property="og:image"
        content={pageImage ? siteUrl + pageImage : siteUrl + ogpImage}
      />
      <meta
        property="og:description"
        content={pageDescription ? pageDescription : siteDescription}
      />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={siteAuthor} />
      <meta name="twitter:title" content={pageTitle} />
      <meta
        name="twitter:image"
        content={pageImage ? siteUrl + pageImage : siteUrl + ogpImage}
      />
      <meta name="twitter:description" content={pageDescription} />
    </Helmet>
  )
}

// SEO.defaultProps = {
//   lang: `ja`,
// }

SEO.propTypes = {
  description: PropTypes.string,
  // lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  // title: PropTypes.string.isRequired,
}

export default SEO
