import React from "react"

const Header = () => (
  <header>
    <div className="container-wide">
      <h1 className="page-head">
        Fumiro Sato <span>NYC based VFX/Finishing Artist </span>
      </h1>
    </div>
  </header>
)

export default Header
