import React from "react"

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container-wide copyright">
          &copy; {new Date().getFullYear()} Fumiro Sato
        </div>
      </footer>
      <div className="body__overlay"></div>
    </>
  )
}

export default Footer
