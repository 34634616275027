import React from "react"

import Header from "./Header"
import Footer from "./Footer"
import "../assets/scss/app.scss"

const Layout = ({ children }) => {
  return (
    <div className="wrapper">
      <Header />
      <main style={{ minHeight: "70vh" }}>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
